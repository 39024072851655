import React from "react"

import "./theorie-intensivkurs-termine-mobile.sass"
import { graphql, useStaticQuery } from "gatsby"
import IntensivkursTermin from "../intensivkurs-termin/intensivkurs-termin"

const TheorieIntensivkursTermineMobile = () => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            theorieIntensivkursTermine {date}
          }
        }
      }
    `,
  )

  const termine = site.siteMetadata.theorieIntensivkursTermine.filter(item => {
    let date = new Date(item.date).getTime();
    return date > new Date();
  });

  return <div className={"_b5"}>
    <div className="_91">
      {termine.map((termin, index) => {
        return <IntensivkursTermin key={index} termin={termin}></IntensivkursTermin>
      })}
    </div>
  </div>
}

export default TheorieIntensivkursTermineMobile
