import React from "react"

import TheorieIntensivkursTermineMobile from "./theorie-intensivkurs-termine-mobile/theorie-intensivkurs-termine-mobile"
import TheorieIntensivkursTermineDesktop
  from "./theorie-intensivkurs-termine-desktop/theorie-intensivkurs-termine-desktop"
import "./theorie-intensivkurs-termine.sass"

const TheorieIntensivkursTermine = () => (
  <section>
    <div className="_13">
      <div className="_15">
        <h3 className={"_4d _9h"}>Theorie Intensivkurse</h3>
        <div className={"_9g"}>
          <h6 className={"_9i"}>Deine Vorteile</h6>
          <ul className={"_9j"}>
            <li><p>Kein Wochenlanger Theorieunterricht</p></li>
            <li><p>Kompakte und effiziente Ausbildung</p></li>
            <li><p>Effektives Lernen</p></li>
            <li><p>Theorie in 7 Werktagen</p></li>
            <li><p>Keine Mehrkosten</p></li>
            <li><p>Theorieprüfung sofort nach dem Kurs möglich</p></li>
            <li><p><b>JETZT NEU!</b> Exklusiver Intensivkurs – Nur deine Freunde und du.</p><p>Ihr seid eine Gruppe von
              fünf Personen und möchtet euren eigenen Intensivkurs? Wir machen es für euch möglich. Bitte schreibt uns
              zwei Wunschtermine über das Kontaktformular. Wir freuen uns auf Euch!</p></li>

          </ul>
        </div>
      </div>
        <div className="_18">
          <div className="_1m _38 _3m _1o _22">
      <div className="_15">
            <TheorieIntensivkursTermineDesktop/>
          </div>
          </div>
          <div className="_1a _3k _3y _20 _2e">
            <div className="_14">
            <TheorieIntensivkursTermineMobile/>
            </div>
          </div>
      </div>
    </div>
  </section>
)

export default TheorieIntensivkursTermine
