import React from "react"
import PropTypes from 'prop-types'

import "./class-overview-item.sass"
import Link from "gatsby-link"

const ClassOverviewItem = ({children, title, linkPath, linkText}) => {

  return <div className={"_9o"}>
    <p className={"_4f _9p"}>{title}</p>
    <ul className={"_9q"}>

    {children}

    </ul>
      {linkPath && linkText &&
      <Link className={"_9r _4t"} target={'_blank'} to={linkPath}>{linkText}</Link>
      }
  </div>
}

ClassOverviewItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  linkPath: PropTypes.string,
  linkText: PropTypes.string
}

export default ClassOverviewItem
