import React, { useState } from "react"

import "./theorie-intensivkurs-termine-desktop.sass"
import { graphql, useStaticQuery } from "gatsby"
import classNames from 'classnames'
import IntensivkursTermin from "../intensivkurs-termin/intensivkurs-termin"

const TheorieIntensivkursTermineDesktop = () => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            theorieIntensivkursTermine {date}
          }
        }
      }
    `,
  )
  const [allVisible, setAllVisible] = useState(false)

  const termine = site.siteMetadata.theorieIntensivkursTermine.filter(item => {
    let date = new Date(item.date).getTime();
    return date > new Date();
  });

  return <div className={classNames("_97", {"_9c": !allVisible})}>
    <div className="_9b">
      {termine.map((termin,index) => {
        return <IntensivkursTermin key={index} termin={termin}></IntensivkursTermin>
      })}
    </div>
      <div className={classNames({"_9d": termine.length <= 4}, {"_9e": termine.length === 3}, {"_9f": termine.length <=3}, '_98 _4n', {"_9a": allVisible})} onClick={() => setAllVisible(!allVisible)}>{allVisible ? 'Weniger Termine anzeigen' : 'Alle Termine anzeigen'}</div>
  </div>
}

export default TheorieIntensivkursTermineDesktop
