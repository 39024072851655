import React from "react"
import PropTypes from 'prop-types'

import "./anmeldung-cta-button.sass"
import Link from "gatsby-link"

const AnmeldungCtaButton = ({claimIndex}) => {
  return <div className="_ao">
    <div className={'_13'}>
      <div className="_17">
        <Link to={'/anmeldung/'} className="_4t">Jetzt
          Anmelden</Link>
      </div>
    </div>
  </div>
}

export default AnmeldungCtaButton
